import React, { useEffect, useRef, useState } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import ProtectedRoute from "./Auth/ProtectedRoute";
import NavBar from "./Components/NavBar";
import ProfilePage from "./pages/Profile";
import Surprise from "./pages/Gifts/Surprise";
import birthdayAudio from "./birthday.mp3";

const App = () => {
  const audioRef = useRef(null);
  const [isAudioEnabled, setIsAudioEnabled] = useState(false);

  const location = useLocation();

  useEffect(() => {
    if (audioRef.current) {
      if (isAudioEnabled && (location.pathname === "/home" || location.pathname === "/profile")) {
        audioRef.current.play();
      } else {
        audioRef.current.pause();
      }
    }
  }, [location, isAudioEnabled]);

  const enableAudio = () => {
    setIsAudioEnabled(true);
    audioRef.current.play().catch(() => {
      console.log("Audio play was prevented. It might be a browser restriction.");
    });
  };

  return (
    <div>
      <NavBar />
      {!isAudioEnabled && (
        <button onClick={enableAudio} style={{ position: 'fixed', bottom: '10px', right: '10px' }}>
          Enable Sound
        </button>
      )}
      <audio ref={audioRef} loop>
        <source src={birthdayAudio} type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/home" element={<ProtectedRoute><HomePage /></ProtectedRoute>} />
        <Route path="/profile" element={<ProtectedRoute><ProfilePage /></ProtectedRoute>} />
        <Route path="/gifts" element={<ProtectedRoute><Surprise /></ProtectedRoute>} />
      </Routes>
    </div>
  );
};

const AppWrapper = () => (
  <Router>
    <App />
  </Router>
);

export default AppWrapper;
