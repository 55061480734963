import React from "react";
import ReactDOM from "react-dom/client" ;
import { render } from "react-dom";

import App from "./App";
import { Provider } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "react-toastify/dist/ReactToastify.css";


render(
    <div>

        <App />
    </div>
     ,
  document.getElementById('root')
);