// src/pages/LoginPage.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './LoginPage.css'; // Import the CSS file

const LoginPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleLogin = () => {
    const validUsername = 'cylvia';
    const validPassword = 'happybirthday';

    if (username === validUsername && password === validPassword) {
      localStorage.setItem('authToken', 'your-token');
      navigate('/home');
    } else {
      window.alert('Invalid username or password');
    }
  };

  return (
    <div className="login-body">
      <div className="login-container">
        <h2 className="login-header">Login</h2>
        <div>
          <label className="login-label">
            Username:
            <input 
              type="text" 
              className="login-input"
              value={username} 
              onChange={(e) => setUsername(e.target.value)} 
            />
          </label>
        </div>
        <div>
          <label className="login-label">
            Password:
            <input 
              type="password" 
              className="login-input"
              value={password} 
              onChange={(e) => setPassword(e.target.value)} 
            />
          </label>
        </div>
        <button className="login-button" onClick={handleLogin}>Login</button>
      </div>
    </div>
  );
};

export default LoginPage;
