import React, { useEffect, useState } from "react";
import Confetti from "react-confetti";
import "./HomePage.css";
import photo1 from "../images/1.jpg";
import photo2 from "../images/2.jpg";
import photo3 from "../images/3.jpg";
import photo4 from "../images/4.jpg";
import photo5 from "../images/5.jpg";
import photo6 from "../images/6.jpg";
import { Link } from "react-router-dom";
import Countdown from "react-countdown";
import canvasConfetti from "canvas-confetti";

const HomePage = () => {
  const [fact, setFact] = useState("");
  const [quizIndex, setQuizIndex] = useState(0);
  const [quizResult, setQuizResult] = useState("");
  const [correctAnswers, setCorrectAnswers] = useState(0);
  const [showFinalScore, setShowFinalScore] = useState(false);

  const quizzes = [
    {
      question: "What is Cylvia's favorite color?",
      options: ["A. Yellow", "B. Green", "C. Orange", "D. Brown"],
      correct: "D. Brown",
    },
    {
      question: "What is Cylvia's favorite food?",
      options: [
        "A. Tomato Rice",
        "B. Paneer Manchurian",
        "C. Bisi Bele Bath",
        "D. Aalo paratha",
      ],
      correct: "D. Aalo paratha",
    },
    {
      question: "What is Cylvia's favorite movie?",
      options: ["A. Raja Rani", "B. 3", "C. Alaipaayuthey", "D. OK Kanmani"],
      correct: "D. Raja Rani",
    },
    {
      question: "What is Cylvia's favorite season?",
      options: ["A. Winter", "B. Spring", "C. Summer", "D. Autumn"],
      correct: "A. Winter",
    },
    {
      question: "What is Cylvia's favorite hobby?",
      options: [
        "A. Dance ",
        "B. Drawing",
        "C. Keyboard Playing",
        "D. Art craft",
      ],
      correct: "Loving Kevin (out of the box)",
    },
  ];
  useEffect(() => {
    const duration = 8 * 1000; // Duration of confetti in milliseconds
    const animationEnd = Date.now() + duration;
    const defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };

    function randomInRange(min, max) {
      return Math.random() * (max - min) + min;
    }

    const colors = [
      "#ff0a54",
      "#ff477e",
      "#ff7096",
      "#ff85a1",
      "#fbb1bd",
      "#f9bec7",
      "#f7cad0",
      "#ff0a54",
      "#ff477e",
      "#ff7096",
      "#ff85a1",
      "#fbb1bd",
      "#f9bec7",
      "#f7cad0",
      "#bb0000",
      "#ffffff",
      "#00ff00",
      "#0000ff",
      "#ff00ff",
    ];

    const shapes = ["circle", "square", "triangle", "star"];

    const interval = setInterval(() => {
      const timeLeft = animationEnd - Date.now();

      if (timeLeft <= 0) {
        return clearInterval(interval);
      }

      const particleCount = 100 * (timeLeft / duration);
      canvasConfetti(
        Object.assign({}, defaults, {
          particleCount,
          origin: {
            x: Math.random(),
            y: Math.random() - 0.2,
          },
          colors: colors,
          shapes: shapes,
          scalar: randomInRange(0.6, 1.2),
        })
      );
    }, 250);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    fetchRandomBirthdayFact();
  }, []);

  const fetchRandomBirthdayFact = async () => {
    const facts = [
      "Did you know? The average number of candles on a birthday cake in the US is 1.9.",
      "On average, people eat 1.5 slices of birthday cake on their birthday.",
      "The world’s largest birthday cake weighed over 128,000 pounds!",
    ];
    setFact(facts[Math.floor(Math.random() * facts.length)]);
  };

  const handleQuizAnswer = (answer) => {
    if (answer === quizzes[quizIndex].correct) {
      setQuizResult("Correct! 🎉");
      setCorrectAnswers(correctAnswers + 1);
    } else {
      setQuizResult(
        "Oops! The correct answer was: " + quizzes[quizIndex].correct
      );
    }
    setTimeout(() => {
      if (quizIndex < quizzes.length - 1) {
        setQuizIndex(quizIndex + 1);
        setQuizResult("");
      } else {
        setShowFinalScore(true);
      }
    }, 2000);
  };

  const Completionist = () => <span>It's your birthday now! 🎉</span>;

  return (
    <div className="home-page">
      <Confetti />

      <div className="header">
        <h1>Welcome to Cylvia's Birthday Celebration! 🎉💕</h1>
        <p className="intro">
          Join us in celebrating this special day with lots of fun and
          surprises!
        </p>
      </div>
      <section className="birthday-countdown">
        <h3>⏰ Birthday Countdown ⏰</h3>
        <Countdown date={new Date().setHours(24, 0, 0, 0)}>
          <Completionist />
        </Countdown>
      </section>
      <section className="birthday-message">
        <h2>🎂 A Heartfelt Birthday Tribute to My One and Only Cylvia 🎂</h2>
        <div className="message">
          <p>My Beloved Cylvia,</p>
          <p>
            On this day, as we celebrate the incredible person that you are, I
            am overwhelmed with gratitude and joy. You are the light that
            brightens my days, the melody that fills my heart with endless
            happiness. Your presence in my life is a blessing I cherish every
            single moment, and I want you to know just how profoundly you are
            loved.
          </p>
          <p>
            From the very first time our paths crossed, my world transformed in
            ways I could never have imagined. Your warmth, your grace, and the
            sheer beauty of your spirit have captured my heart completely. You
            are my muse, my confidante, my greatest adventure, and I am
            endlessly amazed by the depth of your love and kindness.
          </p>
          <p>
            Every day with you is a precious gift, a chance to experience the
            boundless joy and love that you bring into my life. Your laughter is
            my favorite sound, your smile is the highlight of my day, and your
            embrace is where I find my greatest comfort and happiness.
          </p>
          <p>
            Today, on your special day, I want to celebrate you for all that you
            are and all that you mean to me. You are the very essence of my
            happiness, and I am so incredibly fortunate to share this journey
            with you. Your love has transformed my life in ways words cannot
            fully capture, and I am devoted to making every day as wonderful and
            memorable as you are.
          </p>
          <p>
            May this birthday be filled with the magic and wonder you so richly
            deserve. May you be surrounded by the warmth of those who love you
            and may all your dreams and wishes come true. You are my everything,
            and I am eternally grateful for every moment we share together.
          </p>
          <p>
            Happy Birthday, my dearest Muu..! You are my heart, my soul, my
            everything, and I will love you always and forever.
          </p>
          <p>
            With all my boundless love and adoration,
            <br />
            Kevin...✨
          </p>
        </div>
      </section>

      <section className="birthday-gallery">
        <h3>📸 Birthday Photo Gallery 📸</h3>
        <div className="gallery">
          <img src={photo2} alt="Birthday 1" />
          <img src={photo3} alt="Birthday 1" />
          {/* <img src={photo4} alt="Birthday 1" /> */}
          <img src={photo6} alt="Birthday 1" />
          <img src={photo5} alt="Birthday 1" />
          <img src={photo1} alt="Birthday 1" />
        </div>
      </section>

      <section className="birthday-fact-section">
        <h3>🎉 Did You Know? 🎉</h3>
        {fact && <p className="birthday-fact">{fact}</p>}
      </section>

      <section className="birthday-quiz">
        <h3>🎈 Fun Birthday Quiz 🎈</h3>
        <div className="quiz">
          {!showFinalScore ? (
            <>
              <p>{quizzes[quizIndex].question}</p>
              {quizzes[quizIndex].options.map((option, index) => (
                <button
                  key={index}
                  className="quiz-button"
                  onClick={() => handleQuizAnswer(option)}
                >
                  {option}
                </button>
              ))}
            </>
          ) : (
            <div className="final-score">
              <h3>🎉 You've completed the quiz! 🎉</h3>
              <p>
                Your Score: {correctAnswers} / {quizzes.length}
              </p>
              {correctAnswers === quizzes.length && (
                <p>Congratulations! You got all answers correct! 🎉</p>
              )}
            </div>
          )}
        </div>
        {quizResult && <p className="quiz-result">{quizResult}</p>}
      </section>

      <section className="birthday-wishes">
        <h3>💌 Birthday Wishes from Friends 💌</h3>
        <div className="wishes">
          <p>
            "Hai... Chillu kutty, God bless youuuu Happy birthday to you!" -
            Paati
          </p>
          <p>
            "என் கண்ணான கண்ணுக்கு, என் மூச்சாகிய என் மகளுக்கு, பிறந்த நாள்
            வாழ்த்துகள்! " - Amma
          </p>
          <p>
            {" "}
            "If I could wish for one thing, I take the smile that you bring!
            Wherever you go in this World, I'll come along! Happy birthday to my
            dearmost KuttuMoo" -Akka
          </p>
          <p>"இனிய பிறந்தநாள் நல்வாழ்த்துக்கள் chillu..! " - Naseer BRO</p>
          <p>"Haapppppyyy Birrthhhaayyy Chithi mommyy" - Jebin</p>
          <p>"Happy Birthday Cyl uh."- Abdul Ariz</p>
          <p>"Happy Birthday Muuuu...!" - Kevin</p>
        </div>
      </section>

      <section className="birthday-gift">
        <h3>🎁 Claim Your Birthday Gift 🎁</h3>
        <Link to={"/gifts"}>
          <button className="birthday-button">
            👉 Claim Your Birthday Gift 👈
          </button>
        </Link>
      </section>
    </div>
  );
};

export default HomePage;
