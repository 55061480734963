import React from 'react';
import './ProfilePage.css'; // Import the CSS file
import profile from '../images/profile.jpg';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css'; // Import the gallery CSS

// Import all photos from the profile_photos directory
const importAll = (r) => {
  return r.keys().map(r);
};

const images = importAll(require.context('./grpPic', false, /\.(png|jpe?g|svg)$/)).map((image) => ({
  original: image,
  thumbnail: image,
}));

const ProfilePage = () => {
  return (
    <div className="profile-page">
      <h2 className="profile-heading">About Cylvia</h2>
      <div className="profile-content">
        <div className="profile-photo">
          <img
            src={profile} // Replace with an actual photo URL
            alt="Cylvia"
            className="photo-img"
          />
        </div>
        <div className="profile-details">
          <h3>Name: <span className="detail">Cylvia Newton</span></h3>
          <h3>Age: <span className="detail">24</span></h3>
          <h3>Bio:</h3>
          <p className="bio-text">
            Hello! I'm Cylvia. I am passionate about technology and coding. I
            enjoy working on exciting projects and learning new skills. In my
            free time, I love exploring new places and trying out new recipes!
          </p>
        </div>
        <div className="profile-education">
          <h3>Education:</h3>
          <p>
            Bachelor of Technology in Information Technology <br />
            Hindusthan College of Engineering and Technology, Coimbatore |
            August 2018 - June 2022 <br />
            CGPA 9.0
          </p>
        </div>
        <div className="profile-education">
          <h3>12th :</h3>
          <p>
            St.Joseph’s Matriculation Higher Secondary School, Madurai | June
            2017 - May 2018 <br />
            First class with 74%
          </p>
        </div>
        <div className="profile-education">
          <h3>10th :</h3>
          <p>
            St.Joseph’s Matriculation Higher Secondary School, Madurai | June
            2015 - May 2016 <br />
            First class with 98%
          </p>
        </div>
        <div className="profile-skills">
          <h3>Skills:</h3>
          <ul>
            <li>JavaScript</li>
            <li>PostgreSQL</li>
            <li>C</li>
            <li>C++</li>
            <li>Microsoft Excel</li>
            <li>Microsoft Powerpoint</li>
          </ul>
        </div>
        <div className="profile-hobbies">
          <h3>Hobbies:</h3>
          <ul>
            <li>Traveling</li>
            <li>Cooking</li>
            <li>Sleeping</li>
            <li>Playing keyboard</li>
            <li>Disturbing loved ones</li>
            <li>Watching F.R.I.E.N.D.S on Netflix</li>
            <li>Loving her Boyfriend to the infinite</li>
          </ul>
        </div>
        <div className="profile-contact">
          <h3>Contact Information:</h3>
          <p>
            Email: <a>Thara mudiyathu</a>
          </p>
          <p>Phone Number: <a>Vaipu eh ila kelambu</a></p>
          <p>
            LinkedIn:{" "}
            <a>Solla mudiyathu</a>
          </p>
        </div>
        <div className="profile-photos">
          <h3>Photos:</h3>
          <ImageGallery items={images} showThumbnails={true} showFullscreenButton={true} showPlayButton={false} />
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
