import React, { useState } from "react";
import "./Surprise.css"; // Import the CSS file
import video from "./athai-poem_7.mp4";
import video1 from "./akka-song.mp4";

const Surprise = () => {
  const [selectedPerson, setSelectedPerson] = useState("");

  const messages = {
    dad: "Dad, you're the best! Thank you for all your love and support!",
    mom: "Happy Birthday chillu kutty...",
    sister:
      "Sister, you're fantastic! I cherish all our memories and moments together!",
  };

  return (
    <div className="surprise-container">
      <h2 className="surprise-heading">Surprise Messages</h2>
      <h4>"Happy Birthday chillu kutty..."</h4>
      <div className="media-container">
        <video className="media-video" controls src={video}>
          Your browser does not support the video tag.
        </video>
      </div>
      <br/>
      <h4>"Happy Birthday KuttuMoo..."</h4>

      <div className="media-container">
        <video className="media-video" controls src={video1}>
          Your browser does not support the video tag.
        </video>
      </div>
      {/* <div className="dropdown-container">
        <select 
          className="dropdown"
          value={selectedPerson}
          onChange={(e) => setSelectedPerson(e.target.value)}
        >
          <option value="" disabled>🔥</option>
          <option value="mom">Mom</option>
          <option value="sister">Sister</option>
        </select>
      </div>
      {selectedPerson && (
        <div className="message-box">
          <p>{messages[selectedPerson]}</p>
          {selectedPerson === 'mom' && (
            <div className="media-container">
              <video 
                className="media-video"
                controls
                src={video} // Replace with actual video URL
              >
                Your browser does not support the video tag.
              </video>
            </div>
          )}
          {selectedPerson === 'sister' && (
            <div className="media-container">
              <audio 
                className="media-audio"
                controls
                src="https://www.example.com/sister-music.mp3" // Replace with actual audio URL
              >
                Your browser does not support the audio element.
              </audio>
            </div>
          )}
        </div>
      )} */}
    </div>
  );
};

export default Surprise;
